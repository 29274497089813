import { ChangeEvent, FC, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FileUtils } from '../../../utils/FileUtils';
import AttachmentIcon from '../icon/AttachmentIcon';
import { FileInfo } from './FileInfo';
import mimeMatch from 'mime-match';
import { useTranslation } from 'react-i18next';

type UploadAreaProps = {
  multiple?: boolean;
  rounded?: boolean;
  className?: string;
  acceptMimes?: string;
  disabled?: boolean;
  onUpload: (file: FileInfo[]) => void;

  children?: ReactNode | undefined | ((triggerFileDialogue: () => void, uploadContent: ReactElement) => ReactNode | ReactElement | undefined);
};

const UploadArea: FC<UploadAreaProps> = (props) => {
  const { multiple, onUpload, rounded, className, acceptMimes, children, disabled } = props;
  const { t } = useTranslation('common');

  const [dragAreaDim, setDragAreaDim] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const dragAreaRef = useRef<HTMLDivElement>(null);

  const preventDefaults = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dimDragArea = () => {
    setDragAreaDim(true);
  };

  const undimDragArea = () => {
    setDragAreaDim(false);
  };

  useEffect(() => {
    const onFileDropped = (e: DragEvent) => {
      preventDefaults(e);
      if (disabled) {
        return;
      }

      const inputFiles = e.dataTransfer?.files;
      if (inputFiles?.length) {
        const files = Array.from(inputFiles).filter((x) => !acceptMimes || acceptMimes.split(',').some((m) => mimeMatch(x.type, m.trim())));
        const mappedFiles = FileUtils.mapFileInfo(multiple ? files : files.length ? [files[0]] : []);
        mappedFiles.length && onUpload(mappedFiles);
      }
    };

    const dragAreaEl = dragAreaRef.current;

    dragAreaEl?.addEventListener('drop', onFileDropped, false);

    // https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    ['dragenter', 'dragover', 'dragleave'].forEach((eventName) => {
      dragAreaEl?.addEventListener(eventName, preventDefaults, false);
    });

    ['dragenter', 'dragover'].forEach((eventName) => {
      dragAreaEl?.addEventListener(eventName, dimDragArea, false);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      dragAreaEl?.addEventListener(eventName, undimDragArea, false);
    });

    return () => {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dragAreaEl?.removeEventListener(eventName, preventDefaults);
      });

      ['dragenter', 'dragover'].forEach((eventName) => {
        dragAreaEl?.removeEventListener(eventName, dimDragArea);
      });

      ['dragleave', 'drop'].forEach((eventName) => {
        dragAreaEl?.removeEventListener(eventName, undimDragArea);
      });

      dragAreaEl?.removeEventListener('drop', onFileDropped);
    };
  }, [dragAreaRef, dragAreaDim, onUpload, multiple, acceptMimes, disabled]);

  const onBrowseClicked = () => {
    inputRef.current?.click();
  };

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
      ? Array.from(event.target.files).filter((x) => !acceptMimes || acceptMimes.split(',').some((m) => mimeMatch(x.type, m.trim())))
      : [];
    if (files?.length) {
      onUpload(FileUtils.mapFileInfo(multiple ? files : [files[0]]));
    }
  };

  const dragAreaOpacity = dragAreaDim ? 'opacity-50 border-semantic-light-1' : 'opacity-100';

  const uploadContent = useMemo(() => {
    return (
      <>
        {!disabled && (
          <>
            <AttachmentIcon className="bg-primary-1 text-primary-1 h-12 w-12 flex-shrink-0 rounded-full bg-opacity-10 p-2" />
            <div className="mt-6 text-center font-medium text-black">{multiple ? t('upload-area.area.multiple') : t('upload-area.area.single')}</div>
            <div className="mx-auto my-3 max-w-xs text-center">{t('upload-area.or')}</div>
            <div className="mx-auto max-w-xs text-center">
              <button data-cy="browse-files" className="cursor-pointer font-medium text-black underline" onClick={onBrowseClicked}>
                {t('upload-area.browse')}
              </button>
            </div>
          </>
        )}
      </>
    );
  }, [disabled, multiple, t]);

  return (
    <div className={className}>
      <input
        data-cy="file-upload"
        type="file"
        disabled={disabled}
        ref={inputRef}
        className="hidden"
        onChange={onFileChanged}
        multiple={multiple}
        accept={acceptMimes}
        aria-label={t('aria-label.file-upload')}
      />

      <div
        className={`border-gray-4 mx-auto my-2 flex flex-col items-center justify-center border-2 border-dashed bg-white p-8 ${dragAreaOpacity} ${
          rounded ? 'h-[300px] w-[300px] overflow-hidden rounded-full' : 'h-full'
        } relative`}
        ref={dragAreaRef}
        data-testid="drop-area"
        data-cy="file-upload-area"
      >
        <div>{typeof children === 'function' ? children(onBrowseClicked, uploadContent) : children}</div>

        <div className="hidden text-center [div:empty+&]:block">{uploadContent}</div>
      </div>
    </div>
  );
};

export default UploadArea;
